import moment from 'moment/moment';

import {
  ACCESS_TYPE_PRIVATE,
  STATUS_BACKLOG,
  STATUS_CANCELLED,
  STATUS_CHECK,
  STATUS_DONE,
  STATUS_FOR_EXECUTION,
  STATUS_IN_WORK,
  STATUS_PENDING,
  TASK_TYPES,
  TYPE_ACQUAINTANCE,
  TYPE_AGREEMENT,
  TYPE_MEETING,
  TYPE_TASK
} from 'constants/index';

import { fetchSubtaskList } from 'store/tasks';

export const checkIsTopTask = (task = {}) => !task.parent;

export const checkIsTaskStatusCancelled = status => status === STATUS_CANCELLED;
export const checkIsTaskStatusDone = status => status === STATUS_DONE;
export const checkIsTaskStatusBacklog = status => status === STATUS_BACKLOG;
export const checkIsTaskStatusInWork = status => status === STATUS_IN_WORK;
export const checkIsTaskStatusForExecution = status =>
  status === STATUS_FOR_EXECUTION;
export const checkIsTaskStatusCheck = status => status === STATUS_CHECK;
export const checkIsTaskStatusPending = status => status === STATUS_PENDING;

export const checkIsTaskKindAgreement = kind => kind === TYPE_AGREEMENT;
export const checkIsTaskKindTask = kind => kind === TYPE_TASK;
export const checkIsTaskKindAcquaintence = kind => kind === TYPE_ACQUAINTANCE;
export const checkIsTaskKindMeeting = kind => kind === TYPE_MEETING;

export const checkIsChildTaskKindAgreement = ({ task, parentTask }) =>
  checkIsTaskKindAgreement(task.kind) &&
  !checkIsTopTask(task) &&
  parentTask &&
  !checkIsTaskKindTask(parentTask.kind);
export const checkIsChildTaskKindAcquaintence = ({ task, parentTask }) =>
  checkIsTaskKindAcquaintence(task.kind) &&
  !checkIsTopTask(task) &&
  parentTask &&
  !checkIsTaskKindTask(parentTask.kind);
export const checkIsChildTaskKindMeeting = ({ task, parentTask }) =>
  checkIsTaskKindMeeting(task.kind) &&
  !checkIsTopTask(task) &&
  parentTask &&
  !checkIsTaskKindTask(parentTask.kind);

export const checkAllowEditTask = ({ task = {}, parentTask }) => {
  const { permissions = {}, status } = task;

  return (
    permissions.updateTask &&
    !checkIsTaskStatusCancelled(status) &&
    !checkIsTaskStatusDone(status) &&
    !checkIsChildTaskKindAgreement({ task, parentTask }) &&
    !checkIsChildTaskKindAcquaintence({ task, parentTask }) &&
    !checkIsChildTaskKindMeeting({ task, parentTask })
  );
};

export const getTaskDescriptionText = description => {
  if (!description || !Array.isArray(description)) {
    return '';
  }

  return (description[0] || {}).text;
};

export const checkAllowCancelTask = (task = {}) => {
  const { permissions = {}, status, kind } = task;

  return (
    permissions.cancelTask &&
    !checkIsTaskStatusCancelled(status) &&
    !checkIsTaskStatusDone(status) &&
    !checkIsTaskKindAcquaintence(kind)
  );
};

export const checkChangedDates = async ({
  dispatch,
  task,
  dateStart,
  dateEnd,
  setVisibleModal
}) => {
  if (
    ((dateStart || task.dateStart) &&
      !moment(task.dateStart).isSame(dateStart, 'minutes')) ||
    !moment(task.dateEnd).isSame(dateEnd, 'minutes')
  ) {
    const isTypeTask = task.kind.includes(TYPE_TASK);

    const { results } = await dispatch(fetchSubtaskList({ id: task.id }));

    const isHasSubtasks = !!results.filter(
      r => r.status !== STATUS_CANCELLED && r.status !== STATUS_DONE
    ).length;

    if (isTypeTask && isHasSubtasks) {
      setVisibleModal(true);
    }
  }
};

export const getTaskIconData = ({ initialIcon, kind, accessType }) => {
  const task = TASK_TYPES.find(tt => kind.includes(tt.id));

  const isLimitedAccess =
    kind === TYPE_TASK && accessType === ACCESS_TYPE_PRIVATE;

  return {
    iconData: !isLimitedAccess
      ? {
          icon: initialIcon || task?.icon || null,
          label: task?.label,
          ns: task?.ns
        }
      : null,
    limitedIconData: isLimitedAccess
      ? {
          icon: task?.accessIcon,
          label: task?.accessLabel,
          ns: task?.accessNs
        }
      : null
  };
};
