import { useState } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { TYPE_TASK } from 'constants/index';

import { useUpdateTags } from 'components/common/tags';
import useManageSubscribers from 'components/common/subscriptions/use-manage-subscribers';
import useValidityDate from 'components/common/validity-date/use-validity-date';

import { createRequest, createRequestsInBackground } from 'store/requests';

import { useModalsService } from 'services/modals';
import mapValue from 'utils/map-value';
import { NOTICE_NUMBER, showNoticeMessage } from 'services/notice';

import { clearAccordingType, getTaskValue } from '../utils';

export const useCreatorDrawerContext = ({
  visible,
  initialPageProp = 0,
  value,
  onSubmit,
  onTasksCreated,
  onClose
}) => {
  const dispatch = useDispatch();
  const { state = {}, pathname } = useLocation();
  const { initialData } = state;

  const resultValue = initialData || value || {};

  const isBacklog = pathname.includes('/backlog');
  const isSprints = pathname.includes('/sprints');

  const [currentTaskType, setCurrentTaskType] = useState(resultValue.kind);
  const [isLoading, setIsLoading] = useState(false);

  const modals = useModalsService();
  const updateTags = useUpdateTags();
  const { manageBulkSubscribers } = useManageSubscribers();
  const { handleChangeBulkValidityDates } = useValidityDate();

  const { t } = useTranslation('Toast');

  const getTasksId = tasks => tasks.map(task => task.id);

  const onCreateRequest = async ({ tags, ...data }, responsibleId) => {
    if (onSubmit) {
      return dispatch(
        onSubmit({
          ideaId: resultValue.ideaId,
          value: {
            ...data,
            responsible: responsibleId
          }
        })
      );
    }

    const request = await dispatch(
      createRequest({
        task: {
          ...data,
          responsible: responsibleId
        }
      })
    );

    await updateTags({
      entityType: TYPE_TASK,
      entityId: request.id,
      initialTags: [],
      newTags: tags
    });

    await manageBulkSubscribers(data.fileList);

    await handleChangeBulkValidityDates({
      fileList: data.fileList,
      isFromEditor: true
    });

    return request;
  };

  const onCreateOneRequest = async ({ values }) => {
    const request = await onCreateRequest(values, values.responsible[0]);

    if (resultValue.withShowDetails) {
      if (resultValue.parent) {
        modals.requests.showDetails({
          id: resultValue.parent
        });
      } else {
        modals.requests.showDetails({
          id: request.id
        });
      }
    }

    return request;
  };

  const onCreateMultiplyRequests = async data => {
    // при создании <=5 задач, создаем их по одной, иначе в фоне
    const part = 4;
    const requests = [];

    const firstRequest = await onCreateRequest(data, data.responsible[0]);
    requests.push(firstRequest);

    const responsiblies = data.responsible.slice(1);

    if (responsiblies.length <= part) {
      const fetchedTasks = await Promise.all(
        responsiblies.map(item =>
          onCreateRequest({ ...data, fileList: firstRequest.fileIds }, item)
        )
      );

      requests.push(...fetchedTasks);

      showNoticeMessage({
        customContent: t('TasksWithIdCreated', {
          taskIds: getTasksId(requests).join(', ')
        })
      });
    } else {
      await dispatch(
        createRequestsInBackground({
          task: {
            ...data,
            responsible: responsiblies,
            fileList: firstRequest.fileIds
          }
        })
      );

      showNoticeMessage({ number: NOTICE_NUMBER.multipleTaskCreated });
    }

    return requests;
  };

  const handleSubmit = async ({
    saveAsTemplate,
    updateTemplate,
    ...values
  }) => {
    let requests = [];

    try {
      setIsLoading(true);

      const resultTask = getTaskValue({
        ...value,
        ...values,
        dateStart:
          values.dateStart && moment(values.dateStart).toISOString(true),
        dateEnd: values.dateEnd && moment(values.dateEnd).toISOString(true),
        kind: currentTaskType,
        coResponsibles: (values.coResponsibles || []).map(mapValue),
        members: values.members || [],
        approvingManager: (values.approvingManager || {}).value
      });

      const filteredData = clearAccordingType(resultTask);

      if (filteredData.responsible.length <= 1) {
        const request = await onCreateOneRequest({
          values: {
            ...filteredData
          }
        });

        requests = [request];
      } else {
        requests = await onCreateMultiplyRequests({
          ...filteredData
        });
      }

      if (onTasksCreated) {
        await onTasksCreated(requests);
      }

      onClose();
    } finally {
      setIsLoading(false);
    }
  };

  return {
    values: value,
    currentTaskType,
    setCurrentTaskType,
    isLoading,
    setIsLoading,
    isBacklog,
    isSprints,
    value,
    handleSubmit
  };
};

export default useCreatorDrawerContext;
