import { isEqual, isEmpty, isNumber, isDate } from 'lodash';

import {
  TYPE_ACQUAINTANCE,
  TYPE_ADDRESS,
  TYPE_CALL,
  TYPE_CONTACT_TO,
  TYPE_CONTROLLER,
  TYPE_DATE_START,
  TYPE_ESTIMATION,
  TYPE_FILELIST,
  TYPE_LETTER,
  TYPE_LETTER_KIND,
  TYPE_LOCATION,
  TYPE_MEETING,
  TYPE_MEMBERS,
  TYPE_PROJECT,
  TYPE_RELATIONS,
  TYPE_TIME,
  TYPE_AGREEMENT,
  TYPE_TAGS,
  TYPE_CO_RESPONSIBLES,
  TYPE_APPROVING_MANAGER,
  TYPE_COMPLETE_REQUIRE,
  TYPE_MEETING_CONTACTS,
  TYPE_REQUEST,
  REQUESTS,
  TYPE_TASK
} from 'constants/index';

import convertToMinutes from 'utils/convert-to-minutes';
import mapValue from 'utils/map-value';

export const getFieldsByTypes = (currentTaskType, activeFields) => {
  let includedFields = [];

  switch (currentTaskType) {
    case TYPE_ACQUAINTANCE:
      if (!activeFields.includes(TYPE_CO_RESPONSIBLES)) {
        includedFields = [...includedFields, TYPE_CO_RESPONSIBLES];
      }
      break;
    case TYPE_CALL:
    case TYPE_LETTER:
      if (!activeFields.includes(TYPE_CONTACT_TO)) {
        includedFields = [...includedFields, TYPE_CONTACT_TO];
      }
      break;
    case TYPE_MEETING:
      if (!activeFields.includes(TYPE_CO_RESPONSIBLES)) {
        includedFields = [...includedFields, TYPE_CO_RESPONSIBLES];
      }
      if (!activeFields.includes(TYPE_DATE_START)) {
        includedFields = [...includedFields, TYPE_DATE_START];
      }
      if (!activeFields.includes(TYPE_MEETING_CONTACTS)) {
        includedFields = [...includedFields, TYPE_MEETING_CONTACTS];
      }
      break;
    default:
      break;
  }
  return includedFields;
};

export const isAllowedToDelete = (fieldType, currentTaskType) => {
  const acquaintanceObligatoryFields = [TYPE_CO_RESPONSIBLES];
  const meetingObligatoryFields = [
    TYPE_DATE_START,
    TYPE_CO_RESPONSIBLES,
    TYPE_MEETING_CONTACTS
  ];
  const callObligatoryFields = [TYPE_CONTACT_TO];
  const letterObligatoryFields = [TYPE_CONTACT_TO, TYPE_LETTER_KIND];

  switch (currentTaskType) {
    case TYPE_ACQUAINTANCE:
      return !acquaintanceObligatoryFields.includes(fieldType);
    case TYPE_MEETING:
      return !meetingObligatoryFields.includes(fieldType);
    case TYPE_CALL:
      return !callObligatoryFields.includes(fieldType);
    case TYPE_LETTER:
      return !letterObligatoryFields.includes(fieldType);
    default:
      return true;
  }
};

export const getFullFilledFields = ({ defaultValues, activeFields }) => {
  if (!defaultValues) return [];
  // открываем дополнительные поля при наличии
  const keysMap = {
    [TYPE_MEMBERS]: TYPE_MEMBERS,
    [TYPE_PROJECT]: TYPE_PROJECT,
    [TYPE_FILELIST]: TYPE_FILELIST,
    [TYPE_CONTROLLER]: TYPE_CONTROLLER,
    [TYPE_DATE_START]: TYPE_DATE_START,
    [TYPE_ESTIMATION]: TYPE_TIME,
    [TYPE_LOCATION]: TYPE_ADDRESS,
    [TYPE_RELATIONS]: TYPE_RELATIONS,
    [TYPE_TAGS]: TYPE_TAGS,
    [TYPE_APPROVING_MANAGER]: TYPE_APPROVING_MANAGER,
    [TYPE_COMPLETE_REQUIRE]: TYPE_COMPLETE_REQUIRE
  };

  return Object.entries(keysMap).reduce((acc, [key, localKey]) => {
    const value = defaultValues[key];

    if (localKey === TYPE_TIME && defaultValues.isStoryPointEstimation) {
      acc.push(localKey);
    }

    if (localKey === TYPE_RELATIONS && !!defaultValues.defaultRelationType) {
      acc.push(localKey);
    }

    if (localKey === TYPE_TIME && !value) {
      return acc;
    }

    if (
      (!isEmpty(value) || isNumber(value) || isDate(value)) &&
      !acc.includes(localKey)
    ) {
      acc.push(localKey);
    }

    return acc;
  }, activeFields);
};

export const getChangedFgreement = (agreementSteps, newAgreementSteps) => {
  if (isEqual(agreementSteps, newAgreementSteps)) {
    return undefined;
  }

  return newAgreementSteps;
};

export const getAgreementSteps = (kind, agreementSteps) => {
  if (kind !== TYPE_AGREEMENT) {
    return undefined;
  }

  return Object.keys(agreementSteps || {}).reduce((acc, key) => {
    const { employees } = agreementSteps[key];

    if (!(employees || []).length) {
      return acc;
    }

    acc.push(employees.map(({ value }) => value));

    return acc;
  }, []);
};

export const getAttachmentId = (kind, fileList) => {
  if (kind !== TYPE_AGREEMENT) {
    return undefined;
  }

  const file = fileList.find(({ versionId }) => versionId);

  return file && file.versionId;
};

export const clearAccordingType = values => {
  switch (values.kind) {
    case TYPE_REQUEST:
      return { ...values, letterKind: undefined };
    default:
      return values;
  }
};

export const getTaskValue = ({
  kind,
  dateEnd,
  description,
  responsible,
  controller,
  members,
  ...task
}) => {
  const getResponsible = () => {
    if (!responsible) {
      return [];
    }

    return Array.isArray(responsible)
      ? responsible.map(mapValue).filter(Boolean)
      : [responsible.value];
  };

  return {
    ...task,
    dateEnd: dateEnd || undefined,
    description: description || undefined,
    kind: TYPE_REQUEST,
    responsible: getResponsible(),
    controller: (controller || {}).value,
    estimation: convertToMinutes(task).toString(),
    members: (members || []).map(mapValue),
    attachmentVersion: getAttachmentId(kind, task.fileList)
  };
};
