import React from 'react';
import { Translation } from 'react-i18next';
import { Select } from 'antd';

import {
  ACCESS_TYPE_PRIVATE,
  ACCESS_TYPE_PUBLIC,
  ACCESS_TYPE_RESTRICTED,
  TASK_ACCESS_TYPE_PRIVATE_FEATURE_FLAG
} from 'constants/index';

import Icon from 'components/common/icon';
import Typography from 'components/common/typography';
import { useFeatureIsOn } from '@growthbook/growthbook-react';

const hintTextByValue = {
  [ACCESS_TYPE_PUBLIC]: {
    label: 'AvailableToEveryoneDescription',
    ns: 'AddTask'
  },
  [ACCESS_TYPE_RESTRICTED]: {
    label: 'LimitedDescription',
    ns: 'AddTask'
  },
  [ACCESS_TYPE_PRIVATE]: {
    label: 'PrivateDescription',
    ns: 'AddTask'
  }
};

export const AccessToTask = ({
  onChange,
  value,
  isDisabled,
  ...selectProps
}) => {
  const isAccessTypePrivateEnabled = useFeatureIsOn(
    TASK_ACCESS_TYPE_PRIVATE_FEATURE_FLAG
  );

  const ACCESS_TYPES = [
    {
      key: ACCESS_TYPE_PUBLIC,
      value: ACCESS_TYPE_PUBLIC,
      label: 'AvailableToEveryone',
      ns: 'AddTask',
      allow: true
    },
    {
      key: ACCESS_TYPE_RESTRICTED,
      value: ACCESS_TYPE_RESTRICTED,
      label: 'Limited',
      ns: 'AddTask',
      allow: false
    },
    {
      key: ACCESS_TYPE_PRIVATE,
      value: ACCESS_TYPE_PRIVATE,
      label: 'Private',
      ns: 'AddTask',
      allow: isDisabled || isAccessTypePrivateEnabled
    }
  ];

  const filteredAccessTypes = ACCESS_TYPES.filter(type => type.allow);

  return (
    <>
      <Select
        onChange={onChange}
        data-qa="qa-a4qok4vv8xmi7m7"
        value={value}
        suffixIcon={<Icon type="arrow" size={20} color="black-55" />}
        popupClassName="custom-ant-select-dropdown"
        disabled={isDisabled}
        {...selectProps}
      >
        {filteredAccessTypes.map(type => (
          <Select.Option value={type.value} key={type.key}>
            <Translation ns={type.ns}>
              {translate => translate(type.label)}
            </Translation>
          </Select.Option>
        ))}
      </Select>

      {value && (
        <Typography.Text size="small" color="black-55">
          <Translation ns={hintTextByValue[value].ns}>
            {translate => translate(hintTextByValue[value].label)}
          </Translation>
        </Typography.Text>
      )}
    </>
  );
};
